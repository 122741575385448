/* for loader center on differnt screen */
.Loader {
  text-align: center;
  margin-top: 15%;
}


/* Gallery Stock IMages css */



.gallery-image-style {
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.image-name-size {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  color: gray;
  font-size: 12px;
}

.wb-image:hover .image {
  opacity: 0.8;
}
.image-icon {
  width: 2rem;
  font-size: 1rem;
  height: 2rem;
  border-radius: 50%;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.image-icon:hover {
  background: #f1f1f1;
}

.icon-delete:hover {
  color: #f23105 !important;
}
.image-icon i {
  color: #666666;
}

.wb-image .image-icon {
  visibility: hidden;
}

.wb-image:hover .image-icon {
  visibility: visible;
}

.scrollable-image-wrapper {
  overflow: hidden;
  margin: 50px auto;
  cursor: pointer;
}
.scrollable-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  transition: all 1.5s ease-in-out;
}
.scrollable-image-wrapper:hover img {
  object-position: bottom center;
}
.h1-font-size {
  font-size: 24px;
}
.h2-font-size {
  font-size: 1.325rem;
}
.h3-font-size {
  font-size: 1.3rem;
}

@media (max-width: 767px) {
  .Loader {
    text-align: center;
    margin-top: 70%;
  }
}
.jss6 {
  top: 0px !important;

}
.PrivateNotchedOutline-root-6 {
  top: 0px !important;
}

.editor{
  border: 1px solid rgb(190, 190, 190);
  border-radius: 15px;
  padding: 10px;
  /* margin-bottom: 10px; */
}