.MuiFormControl-marginNormal{
    margin-top: 0;
}

.create-project-card {
    box-shadow: 0px 5px 5px rgb(0 0 0 / 7%);
    padding: 8px;
  }
  .create-project-card:hover {
    /* transform: translateY(-2%); */
    transition: ease-in-out 0.3s;
  }
  .create-project-card .create-project-edit-button {
    position: relative;
    height: 100%;
    top: -105%;
    background-color: rgba(22, 45, 61, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .create-project-card .create-project-edit-button {
    opacity: 0;
  }
  .create-project-card:hover .create-project-edit-button {
    opacity: 1;
  }

  .icon {
    float: right;
    width: 30px;
    font-size: 1rem;
    height: 30px;
    border-radius: 50%;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;
  }
  .icon:hover {
    background: #f1f1f1;
  }
  
  .icon i {
    color: #666666;
  }